import PropTypes from 'prop-types';

import dataTypes from './dataTypes';

export const seoDefaultProps = {
  description: '',
  lang: 'en',
  meta: [],
};

const seoTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  site: dataTypes.site,
  title: PropTypes.string.isRequired,
};

export default seoTypes;